import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-467d4620"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-light text-center text-lg-start" }
const _hoisted_2 = { class: "container-fluid py-3" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md" }
const _hoisted_5 = { class: "col-12 col-md-auto ms-md-auto mt-3 mt-md-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericButton = _resolveComponent("GenericButton")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_GenericButton, {
            buttonText: "Terms of Service",
            redirectPath: "/terms-of-service",
            customClass: "btn btn-outline-dark me-md-2"
          }),
          _createVNode(_component_GenericButton, {
            buttonText: "Contact Us",
            redirectPath: "/contact",
            customClass: "btn btn-outline-dark"
          })
        ]),
        _createElementVNode("div", _hoisted_5, " Copyright © " + _toDisplayString(_ctx.currentYear) + " Shirlington Kitchen & Bath LLC. All Rights Reserved. ", 1)
      ])
    ])
  ]))
}