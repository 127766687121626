import verifyToken from "@/services/authUtils";
import { CustomRouteMeta } from "@/types/types";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("@/views/Contact.vue"),
    },
    {
        path: "/terms-of-service",
        name: "tos",
        component: () => import("@/views/TermsOfService.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/views/Register.vue"),
    },
    {
        path: "/layouts",
        name: "layouts",
        component: () => import("@/views/Layouts.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin", "Multi Family"],
        } as CustomRouteMeta,
    },
    {
        path: "/layouts/:id",
        name: "Layout",
        component: () => import("@/views/LayoutPage.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin", "Multi Family"],
        } as CustomRouteMeta,
    },
    {
        path: "/user-profile",
        name: "userprofile",
        component: () => import("@/views/UserProfile.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin", "Contractor", "Multi Family"],
        } as CustomRouteMeta,
    },
    {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/Orders.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin", "Contractor", "Multi Family"],
        } as CustomRouteMeta,
    },
    {
        path: "/rewards",
        name: "rewards",
        component: () => import("@/views/Rewards.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin", "Contractor", "Multi Family"],
        } as CustomRouteMeta,
    },
    {
        path: "/overview",
        name: "overview",
        component: () => import("@/views/AdminOverview.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin"],
        } as CustomRouteMeta,
    },
    {
        path: "/referral-codes",
        name: "codes",
        component: () => import("@/views/ReferralCodes.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin"],
        } as CustomRouteMeta,
    },
    {
        path: "/users/:id",
        name: "adminUserView",
        component: () => import("@/views/AdminUserView.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin"],
        } as CustomRouteMeta,
    },
    {
        path: "/management",
        name: "management",
        component: () => import("@/views/ManagementPage.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ["Admin"],
        } as CustomRouteMeta,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const [valid, userType] = await verifyToken();
        if (!valid && !userType) {
            next({ name: "home" });
        } else {
            const routeMeta = to.meta as CustomRouteMeta;
            const allowedRoles = routeMeta.allowedRoles || [];
            if (allowedRoles.length === 0 || allowedRoles.includes(userType)) {
                next();
            } else {
                next({ name: "home" });
            }
        }
    } else {
        next();
    }
});

export default router;
