<template>
    <button @click="goToRedirect" :class="buttonClass">
        <i v-if="iconClass" :class="['bi', iconClass]"></i>
        {{ buttonText }}
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";

export default defineComponent({
    name: "GenericButton",
    props: {
        buttonText: {
            type: String,
            required: true,
        },
        redirectPath: {
            type: String,
            default: "/",
        },
        customClass: {
            type: String,
            default: "btn btn-primary btn-lg",
        },
        iconClass: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const router = useRouter();
        const goToRedirect = () => {
            router.push(props.redirectPath);
        };

        // Use the custom class
        const buttonClass = props.customClass;

        return { goToRedirect, buttonClass };
    },
});
</script>
