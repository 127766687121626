<template>
    <footer class="bg-light text-center text-lg-start">
        <div class="container-fluid py-3">
            <!-- Use Bootstrap's row and cols for alignment -->
            <div class="row">
                <div class="col-12 col-md">
                    <!-- Buttons aligned to the left -->
                    <GenericButton buttonText="Terms of Service" redirectPath="/terms-of-service"
                        customClass="btn btn-outline-dark me-md-2" />
                    <GenericButton buttonText="Contact Us" redirectPath="/contact" customClass="btn btn-outline-dark" />
                </div>
                <div class="col-12 col-md-auto ms-md-auto mt-3 mt-md-0">
                    <!-- Copyright text aligned to the right -->
                    Copyright &copy; {{ currentYear }} Shirlington Kitchen & Bath LLC. All Rights Reserved.
                </div>
            </div>
        </div>
    </footer>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';
import GenericButton from '@/components/GenericButton.vue';

export default defineComponent({
    components: {
        GenericButton,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
});
</script>
  
<style scoped>
@media (max-width: 768px) {
    .btn {
        display: block;
        /* Stack buttons vertically */
        width: 100%;
        /* Full width buttons */
        margin-bottom: 0.5rem;
        /* Space between buttons */
    }

    .btn:last-child {
        margin-bottom: 0;
        /* No bottom margin for last button */
    }
}</style>
  