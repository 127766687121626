import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToRedirect && _ctx.goToRedirect(...args))),
    class: _normalizeClass(_ctx.buttonClass)
  }, [
    (_ctx.iconClass)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(['bi', _ctx.iconClass])
        }, null, 2))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
  ], 2))
}