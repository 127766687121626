import { defineStore } from "pinia";
import { UserType } from "@/types/types";

export const useAuthStore = defineStore("auth", {
    state: () => ({
        isLoggedIn: false,
        userType: null as UserType | null,
    }),
    actions: {
        logIn(userType: UserType) {
            this.isLoggedIn = true;
            this.userType = userType;
        },
        logOut() {
            this.isLoggedIn = false;
            this.userType = null;
        },
    },
});
