<template>
    <SpeedInsights />
    <div id="app">
        <div class="content">
            <router-view />
        </div>
        <FooterComponent />
    </div>
</template>

<script lang="ts">
import FooterComponent from '@/components/Footer.vue';
import { SpeedInsights } from "@vercel/speed-insights/vue"

export default {
    components: {
        FooterComponent
    },
};
</script>

<style>
#app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content {
    flex-grow: 1;
}
</style>
