import {
    GetLayouts,
    LayoutData,
    LayoutDetails,
    PurchaseRequest,
    UserCreate,
    UserBase,
    InvoiceTypes,
    PaymentTerms,
    ProductView,
} from "@/types/types";
import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {
        "Content-type": "application/json",
    },
});

export default {
    async login(email: string, password: string) {
        try {
            const response = await apiClient.post("/api/auth/login", {
                email,
                password,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async register(userData: UserCreate, code: string) {
        const url = `/api/auth/register?code=${encodeURIComponent(code)}`;
        try {
            const response = await apiClient.post(url, userData);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async logout() {
        try {
            const response = await apiClient.post("/api/auth/logout");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getLayouts(userId?: string) {
        try {
            let url = "/api/layouts";
            if (userId) {
                url += `?optional_user_id=${userId}`;
            }
            const response = await apiClient.get(url);
            return response.data as GetLayouts;
        } catch (error) {
            throw error;
        }
    },
    async getLayout(layoutId: string) {
        try {
            const response = await apiClient.get(`/api/layouts/${layoutId}`);
            return response.data as LayoutDetails;
        } catch (error) {
            throw error;
        }
    },
    async deleteLayout(layoutId: string) {
        try {
            const response = await apiClient.delete(`/api/layouts/${layoutId}`);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async searchProducts(searchTerm?: string) {
        try {
            let url = `/api/search/products`;
            if (searchTerm) {
                url += `?search_term=${encodeURIComponent(searchTerm)}`;
            } else {
                url += `?latest=true`;
            }
            const response = await apiClient.get(url);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    async createLayout(layoutData: LayoutData, userId?: string) {
        try {
            let url = "/api/layouts";
            if (userId) {
                url += `?optional_user_id=${userId}`;
            }
            const response = await apiClient.post(url, layoutData);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async updateLayout(layoutId: string, layoutData: LayoutData) {
        try {
            const response = await apiClient.put(
                `/api/layouts/${layoutId}`,
                layoutData
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    async createInvoice(
        invoiceData: PurchaseRequest,
        poNumber: string,
        paymentTerms: PaymentTerms
    ) {
        try {
            const response = await apiClient.post(`/api/invoices`, {
                products: invoiceData,
                po_number: poNumber,
                payment_terms: paymentTerms,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async verifyToken() {
        try {
            const response = await apiClient.get("/api/auth/verify");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getUserProfile(userId?: string) {
        try {
            let url = "/api/user";
            if (userId) {
                url += `?optional_user_id=${userId}`;
            }
            const response = await apiClient.get(url);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async updateUserProfile(userData: UserBase) {
        try {
            const response = await apiClient.put("/api/user", userData);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getOrders(
        date_from?: string,
        date_to?: string,
        ending_before?: string,
        starting_after?: string,
        status?: InvoiceTypes
    ) {
        try {
            const response = await apiClient.get("api/invoices", {
                params: {
                    date_from,
                    date_to,
                    ending_before,
                    starting_after,
                    status,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getRewards(year?: number) {
        try {
            const response = await apiClient.get("/api/rewards", {
                params: {
                    year,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getManagementGroups() {
        try {
            const response = await apiClient.get("/api/groups");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getManagementGroup(id: number) {
        try {
            const response = await apiClient.get(`/api/groups/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async addManagementGroup(name: string) {
        try {
            const response = await apiClient.post(`/api/groups`, name);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async verifyCode(code: string) {
        try {
            const response = await apiClient.get(`/api/codes/${code}`);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getReferralCodes() {
        try {
            const response = await apiClient.get(`/api/codes`);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async createReferralCode() {
        try {
            const response = await apiClient.post(`/api/codes`);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getUsers() {
        try {
            const response = await apiClient.get("/api/admin/users");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getFiscalRevenue() {
        try {
            const response = await apiClient.get("/api/admin/revenue");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async updatePassword(currentPassword: string, newPassword: string) {
        try {
            const response = await apiClient.post("/api/auth/update-password", {
                old_password: currentPassword,
                new_password: newPassword,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async get3dModels(page?: number) {
        try {
            const response = await apiClient.get("/api/plans", {
                params: {
                    page,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getVendors() {
        try {
            const response = await apiClient.get("api/vendors");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async createVendor(vendor_name: string, description: string) {
        try {
            const response = await apiClient.post("api/products/vendors", {
                vendor_name,
                description,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async updateVendor(
        vendor_id: number,
        vendor_name: string,
        description: string
    ) {
        try {
            const response = await apiClient.put(
                `api/products/vendors/${vendor_id}`,
                {
                    vendor_name,
                    description,
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    async deleteVendor(vendor_id: number) {
        try {
            const response = await apiClient.delete(
                `api/products/vendors/${vendor_id}`
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getCategories() {
        try {
            const response = await apiClient.get("api/categories");
            return response;
        } catch (error) {
            throw error;
        }
    },
    async createCategory(name: string, parent_id?: number) {
        try {
            const response = await apiClient.post("api/products/categories", {
                name,
                parent_id,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async updateCategory(
        category_id: number,
        name: string,
        parent_id?: number
    ) {
        try {
            const response = await apiClient.put(
                `api/products/categories/${category_id}`,
                {
                    name,
                    parent_id,
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    async deleteCategory(category_id: number) {
        try {
            const response = await apiClient.delete(
                `api/products/categories/${category_id}`
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    async addProduct(productData: ProductView) {
        try {
            const response = await apiClient.post("api/products", {
                product_name: productData.product_name,
                tags: productData.tags,
                description: productData.description,
                msrp: productData.msrp,
                sku: productData.sku,
                upc: productData.upc,
                manufacturer: productData.manufacturer,
                color: productData.color,
                internal_note: productData.internal_note,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async updateProduct(productData: ProductView) {
        try {
            const response = await apiClient.put("api/products", {
                product_name: productData.product_name,
                tags: productData.tags,
                description: productData.description,
                msrp: productData.msrp,
                sku: productData.sku,
                upc: productData.upc,
                manufacturer: productData.manufacturer,
                color: productData.color,
                internal_note: productData.internal_note,
                product_id: productData.product_id,
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    async addProductImage(product_id: number, image: FormData) {
        try {
            const response = await apiClient.post(
                `api/products/${product_id}/image`,
                image,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getProductImage(product_id: number) {
        try {
            const response = await apiClient.get(
                `/api/products/${product_id}/image`
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
};
