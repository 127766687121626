import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import apiService from "@/services/apiService";

export default async function verifyToken(redirectOnFail = true) {
    const router = useRouter();
    const authStore = useAuthStore();
    try {
        const response = await apiService.verifyToken();
        if (response.data.valid) {
            authStore.logIn(response.data.type);
            return [true, response.data.type];
        } else {
            if (redirectOnFail) {
                router.push({ name: "home" });
            }
            authStore.logOut();
            return [false, null];
        }
    } catch (error) {
        if (redirectOnFail) {
            router.push({ name: "home" });
        }
        authStore.logOut();
        return [false, null];
    }
}
